<template>
  <v-card :dark="$dark.get()" class="custom-card">
    <!-- Загрузчик данных -->
    <loader v-if="loading" />

    <div v-else>
      <!-- Заголовок с градиентом -->
      <div class="header-gradient">
        <h1 class="header-title">Таблица "Филиалы"</h1>
        <p class="header-description">
          В системе AyanMarket филиалом называется торговая точка, с которой
          продавец производит отгрузку товаров. На Ваших филиалах может быть
          разный ассортимент, разные цены и акционная активность.
        </p>
      </div>

      <div class="buttons-container" v-if="!isProvider">
        <!-- Кнопка просмотра лимитов -->
        <v-btn color="success" rounded @click="redirectToLimits">
          Лимиты Аяна
        </v-btn>

        <!-- Кнопка добавления филиала -->
        <v-btn color="#5d4bd0" dark rounded @click="openAddPage">
          Добавить
          <v-icon right>mdi-plus</v-icon>
        </v-btn>

        <!-- Кнопка управления группами -->
        <v-btn text class="pl-0" :to="{ path: '/department-groups' }">
          <v-icon color="#5d4bd0" class="mr-2">mdi-plus-circle</v-icon>
          Создать новую группу для структурирования филиалов
        </v-btn>
      </div>

      <!-- Таблица филиалов -->
      <div class="table-container">
        <!-- Заголовки таблицы с градиентом -->
        <div class="table-header">
          <v-checkbox
            v-model="selectAll"
            :input-value="isAllSelected"
            @change="toggleSelectAll"
            class="table-header__checkbox"
            hide-details
          ></v-checkbox>
          <div class="table-header__item">ID</div>
          <div class="table-header__item">Название</div>
          <div class="table-header__item">Адрес</div>
          <div class="table-header__item">Профиль</div>
        </div>

        <!-- Список филиалов, сгруппированных по категориям -->
        <div v-if="groupedDepartments && Object.keys(groupedDepartments).length">
          <v-list-group
            v-for="(group, groupId) in groupedDepartments"
            :key="groupId"
            no-action
            color="#5d4bd0"
            class="group-item"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ group.name }} ({{ group.departments.length }}
                  {{
                    declOfNum(group.departments.length, [
                      "филиал",
                      "филиала",
                      "филиалов",
                    ])
                  }})
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="department in group.departments"
              :key="department.id"
              class="department-row"
            >
              <v-list-item-content>
                <div class="department-row__content">
                  <!-- Чекбокс для выбора филиала -->
                  <v-checkbox
                    v-model="selectedDepartments"
                    :value="department.id"
                    class="department-row__checkbox"
                    hide-details
                  ></v-checkbox>

                  <!-- ID филиала -->
                  <div class="department-row__id">{{ department.id }}</div>

                  <!-- Название филиала -->
                  <div class="department-row__name">{{ department.name }}</div>

                  <!-- Адрес филиала -->
                  <div class="department-row__address">
                    {{ formatAddress(department.address) }}
                  </div>

                  <!-- Иконка профиля -->
                  <v-btn
                    icon
                    color="#5d4bd0"
                    @click="redirectToProfile(department.id)"
                  >
                    <v-icon>mdi-arrow-right-circle</v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
        <p v-else>Филиалы с таким именем не найдены</p>
      </div>
    </div>

    <!-- Панель действий при выборе филиалов -->
    <div v-if="selectedDepartments.length > 0" class="actions-panel">
      <v-btn
        icon
        color="#5d4bd0"
        @click="openEditPage"
        :disabled="selectedDepartments.length !== 1"
        title="Редактировать"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon color="error" @click="confirmDelete" title="Удалить">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>

    <!-- Диалог подтверждения удаления филиалов -->
    <v-dialog v-model="showDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Подтвердите удаление</v-card-title>
        <v-card-text>
          Вы уверены, что хотите удалить выбранные филиалы?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDeleteDialog">Отмена</v-btn>
          <v-btn color="error" text @click="deleteDepartments">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import showMessage from "@/Functions/message";

export default {
  
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      selectedDepartments: [],
      showDeleteDialog: false,
      selectAll: false,
      cities: [],
    };
  },
  computed: {
    ...mapGetters({
      departmentsState: "Department/STATE",
      groupsState: "DepartmentGroups/STATE",
      userState: "User/STATE",
    }),
    // Проверка, является ли пользователь поставщиком
    isProvider() {
      return this.userState.userInfo.user.roleId === 5;  // 5 - это пример ID роли поставщика
    },
    groupedDepartments() {
      // Группировка филиалов по категориям
      const departments = this.departmentsState.cityDepartment;

      const groups = {};

      departments.forEach((dept) => {
        const groupId = dept.departmentGroup ? dept.departmentGroup.id : 0;
        const groupName = dept.departmentGroup
          ? dept.departmentGroup.name
          : "Без группы";

        if (!groups[groupId]) {
          groups[groupId] = {
            name: groupName,
            departments: [],
          };
        }

        groups[groupId].departments.push(dept);
      });

      return groups;
    },
    isAllSelected() {
      const allIds = this.departmentsState.cityDepartment.map(
        (dept) => dept.id
      );
      return (
        allIds.length > 0 &&
        allIds.every((id) => this.selectedDepartments.includes(id))
      );
    },
  },
  methods: {
    ...mapActions({
      getAllDepartments: "Department/GET_DEPARTMENT",
      deleteDepartmentAction: "Department/DELETE_DEPARTMENT",
      getAllGroups: "DepartmentGroups/GET_ALL_GROUPS",
      addDepartmentAction: "Department/ADD_DEPARTMENT",
      editDepartmentAction: "Department/EDIT_DEPARTMENT",
    }),
    async initialize() {
      this.loading = true;
      try {
        await this.getAllGroups();
        await this.getAllDepartments(this.$root.city);
        // Получение списка городов
        this.cities = this.userState.userInfo.cities || [];
      } catch (error) {
        showMessage("Ошибка при загрузке данных", false);
        console.error(error);
      }
      this.loading = false;
    },
    declOfNum(number, titles) {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },
    formatAddress(address) {
      return `${address.street}, дом ${address.house}`;
    },
    openAddPage() {
      this.$router.push("/department/new");
    },
    openEditPage() {
      if (this.selectedDepartments.length !== 1) return;
      const departmentId = this.selectedDepartments[0];
      this.$router.push(`/department/${departmentId}`);
    },
    redirectToProfile(id) {
      this.$router.push(`/department-profile/${id}`);
    },
    redirectToLimits() {
      this.$router.push("providers-limit/0");
    },
    confirmDelete() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async deleteDepartments() {
      if (this.selectedDepartments.length === 0) return;

      this.loading = true;
      try {
        // Используем Promise.all для массового удаления
        await Promise.all(
          this.selectedDepartments.map((id) => this.deleteDepartmentAction(id))
        );
        showMessage("Филиалы успешно удалены", true);
        await this.getAllDepartments(this.$root.city);
        this.selectedDepartments = [];
      } catch (error) {
        showMessage("Ошибка при удалении филиалов", false);
        console.error(error);
      }
      this.loading = false;
      this.closeDeleteDialog();
    },
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedDepartments = [];
      } else {
        this.selectedDepartments = this.departmentsState.cityDepartment.map(
          (dept) => dept.id
        );
      }
    },
  },
  watch: {
    selectedDepartments(newVal) {
      // Обновление состояния чекбокса "Выбрать все"
      this.selectAll = this.isAllSelected;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

  <style lang="scss" scoped>
.custom-card {
  padding: 10px;
  position: relative;

  /* Заголовок с градиентом */
  .header-gradient {
    background: linear-gradient(to right, #e93e7b 50%, white 70%);
    padding: 20px;
    border-radius: 8px;
    color: white;
    margin-bottom: 10px;

    .header-title {
      margin: 0;
      font-size: 26px;
      font-weight: bold;
    }

    .header-description {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  /* Кнопки управления */
  .buttons-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  /* Таблица филиалов */
  .table-container {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    overflow: hidden;

    /* Заголовки таблицы */
    .table-header {
      display: flex;
      align-items: center;
      background: linear-gradient(to right, #5d4bd0, #e93e7b);
      color: white;
      padding: 10px;
      font-weight: bold;

      .table-header__checkbox {
        margin-right: 10px;
      }

      .table-header__item {
        flex: 1;
        text-align: center;
      }

      /* Специфичные ширины */
      .table-header__item:nth-child(2) {
        flex: 0.5;
      }
      .table-header__item:nth-child(3),
      .table-header__item:nth-child(4) {
        flex: 2;
      }
      .table-header__item:nth-child(5) {
        flex: 1;
      }
    }

    /* Группы филиалов */
    .group-item {
      background: #f5f5f5;
      margin-bottom: 3px;
      border-radius: 8px;
    }

    /* Строка филиала */
    .department-row {
      display: flex;
      align-items: center;
      padding: 1px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      &:last-child {
        border-bottom: none;
      }

      &__content {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &__checkbox {
        margin-right: 10px;
      }

      &__id {
        flex: 0.5;
        text-align: center;
        font-weight: bold;
      }

      &__name {
        flex: 2;
        text-align: left;
        padding-left: 10px;
      }

      &__address {
        flex: 2;
        text-align: left;
        padding-left: 10px;
      }

      &__profile-btn {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  }

  /* Панель действий */
  .actions-panel {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    gap: 10px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
}
</style>
  